//display
.d-flex{
    display: flex !important;
}
.align-self-center{
    align-self: center;
}
.justify-content-between{
    justify-content: space-between;
}

//typos
.fs-xsm{
    font-size: 10px;
}
.fs-12{
    font-size: 12px;
}
.fs-14{
    font-size: 14px;
}
.text-bold{
    font-weight: bold !important;
}

//colors
.bg-light{
    background-color:#FFFFFF ;
}
.text-muted{
    color: rgba(0, 0, 0, 0.75);
}
.bg-dark-grey{
    background-color: #BDC7D2;
}
.bg-dark-orange{
    background-color: #EADFD4;
}
.text-primary{
    color: #3070C7;
}
.text-underline__dashed{
    border-bottom: #3070C7 0.5px dashed;
}
.border-orange__solid{
    border:0.5px solid rgb(242,128,22)
}


//padding & margins
.text-mt{
    color: #545454 !important;
}
.p-2{
    padding: 1rem !important;
}
.p-3{
    padding: 1.25rem !important;
}
.px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}
.py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.mt-3{
    margin-top: 1rem !important;
}
.mt-5{
    margin-top: 2.5rem !important;
}
.mb-3{
    margin-bottom: 1rem !important;
}
.w-100{
    width: 100% !important;
}
.m-0{
    margin: 0 !important;
}
.mb-2{
    margin-bottom: 0.75rem;
}