@font-face {
    font-family: "Work Bold";
    src: url("assets/fonts/work/static/WorkSans-Bold.ttf");
}

@font-face {
    font-family: "Work Light";
    src: url("assets/fonts/work/static/WorkSans-Light.ttf");
}
@font-face {
    font-family: "Work Thin";
    src: url("assets/fonts/work/static/WorkSans-Thin.ttf");
}
@font-face {
    font-family: "Work Regular";
    src: url("assets/fonts/work/static/WorkSans-Regular.ttf");
}

@font-face {
    font-family: "Work SemiBold";
    src: url("assets/fonts/work/static/WorkSans-SemiBold.ttf");
}

@font-face {
    font-family: "Work Medium";
    src: url("assets/fonts/work/static/WorkSans-Medium.ttf");
}

h1,h2,h3,h4,h5,h6{
    font-family: "Work Regular" !important;
}
strong, .special-char{
    font-family: "Work SemiBold" !important;
}
a,button{
    font-family: "Work Medium" !important;
}
body, p,small{
    font-family: "Work Light" !important;
}

